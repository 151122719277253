<template>
    <div>NOT FOUND 404</div>
</template>

<script>
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'ページが見つかりませんでした。')
    },
}
</script>

<style lang="scss" scoped></style>
